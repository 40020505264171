import React, { Suspense, lazy, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AppHeader from "components/appHeader/AppHeader";
import "react-toastify/dist/ReactToastify.css";
import Loading from "components/loading/Loading";
import Cookies from "js-cookie";
import "./App.css";
import i18n from "./i18n";
import { useLocation } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import DetailPayment from "pages/detail_payment/detail_payment";
import { isAdminFacility, isAdminSanwa, isPartner } from "utils";
import CsvExportSanwa from "./pages/csvExport/CsvExportSanwa";
import PatientDetail from "pages/patient_detail/PatientDetail";
import { FORCE_HTTPS } from "constants/Constants";

const Home = lazy(() => import("./pages/home/Home"));
const Pic = lazy(() => import("./pages/pic/Pic"));
const Group = lazy(() => import("./pages/group/Group"));
const Login = lazy(() => import("./pages/login/Login"));
const FacilityInformationList = lazy(() =>
  import("./pages/facilityInformationList/FacilityInformationList")
);
const PartnerInformationList = lazy(() =>
  import("./pages/partnerList/PartnerList")
);
const FacilityInformation = lazy(() =>
  import("./pages/facilityInformation/FacilityInformation")
);
const PartnerInformation = lazy(() =>
  import("./pages/partnerInformation/PartnerInformation")
);
const DiaperType = lazy(() => import("./pages/diaperType/DiaperType"));
const Device = lazy(() => import("./pages/device/Device"));
// const User = lazy(() => import("./pages/user/User"));
const Password = lazy(() => import("./pages/password/Password"));
const ResetPassword = lazy(() => import("./pages/resetPassword/ResetPassword"));
const Payment = lazy(() => import("./pages/payment/Payment"));
const Deg = lazy(() => import("./pages/deg/Deg"));
const BatVolSetting = lazy(() => import("./pages/batvol/BatVolSetting"));
const AccelerationSetting = lazy(() =>
  import("./pages/acceleration/AccelerationSetting")
);
const UrineAnalytis = lazy(() =>
  import("./pages/home/components/urineAnalytis/UrineAnalytis")
);
const UrineAnalytisExternal = lazy(() =>
  import("./pages/home/components/urineAnalytisExternal/UrineAnalytisExternal")
);

const CsvExportPage = lazy(() => import("./pages/csvExport/CsvExportSanwa"));
// const PatientDetail = lazy(() => import(".pages/patient_detail/PatientDetail"));

const App = () => {
  const lang = localStorage.getItem("LANG");
  const userInfoString = Cookies.get("USER_INFO");
  const [isAuthen, setIsAuthen] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (FORCE_HTTPS === "false") return;
    if (window.location.protocol !== "https:") {
      window.location.replace(
        `https:${window.location.href.substring(
          window.location.protocol.length
        )}`
      );
    }
  }, []);
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    } else {
      localStorage.setItem("LANG", "JP");
      i18n.changeLanguage("JP");
    }
  }, [lang]);
  useEffect(() => {
    setIsAuthen(!!userInfoString);
    if (userInfoString) {
      setUserInfo(JSON.parse(userInfoString));
    }
  }, [userInfoString]);
  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <Router>
          {!isAuthen ? (
            <Suspense fallback={<Loading />}>
              <Switch>
                <Route path="/" exact component={() => <Login />} />
                <Route path="/login" exact component={() => <Login />} />

                <Route
                  path="/:patientId/analytic-urine/external"
                  exact
                  component={() => <UrineAnalytisExternal />}
                />
                <Route
                  path="/reset-password"
                  exact
                  component={() => <ResetPassword />}
                />
              </Switch>
            </Suspense>
          ) : (
            <Suspense fallback={<Loading />}>
              <AppHeader />
              <div
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
                className="app-container"
              >
                <Switch>
                  <Route path="/" exact component={() => <Home />} />
                  <Route path="/pic" exact component={() => <Pic />} />
                  <Route path="/group" exact component={() => <Group />} />
                  <Route
                    path="/master-setting/facility"
                    exact
                    component={() => {
                      if (
                        userInfo.role == "ROLE_FACILITY" &&
                        userInfo.is_sys_admin
                      ) {
                        return <FacilityInformationList />;
                      } else if (
                        userInfo.role == "ROLE_PARTNER" &&
                        !userInfo.is_sys_admin
                      ) {
                        return <FacilityInformationList />;
                      } else if (
                        userInfo.role == "ROLE_FACILITY" &&
                        !userInfo.is_sys_admin
                      ) {
                        return <FacilityInformation />;
                      }
                    }}
                  />
                  {(isPartner(userInfo) || isAdminSanwa(userInfo)) && (
                    <Route
                      path="/master-setting/partner"
                      exact
                      component={() =>
                        userInfo.is_sys_admin ? (
                          <PartnerInformationList />
                        ) : (
                          <PartnerInformation />
                        )
                      }
                    />
                  )}
                  <Route
                    path="/master-setting/diaper-type"
                    exact
                    component={() => <DiaperType />}
                  />
                  <Route
                    path="/master-setting/device"
                    exact
                    component={() => <Device />}
                  />
                  {/* <Route
                    path="/master-setting/user"
                    exact
                    component={() => <User />}
                  /> */}
                  <Route
                    path="/password"
                    exact
                    component={() => <Password />}
                  />
                  <Route
                    path="/reset-password"
                    exact
                    component={() => <ResetPassword />}
                  />
                  <Route path="/payment" exact component={() => <Payment />} />
                  <Route
                    path="/master-setting/deg"
                    exact
                    component={() => <Deg />}
                  />
                  <Route
                    path="/master-setting/acceleration"
                    exact
                    component={() => <AccelerationSetting />}
                  />
                  <Route
                    path="/master-setting/batvol"
                    exact
                    component={() => <BatVolSetting />}
                  />
                  <Route
                    path="/:patientId/analytic-urine"
                    exact
                    component={() => <UrineAnalytis />}
                  />

                  <Route
                    path="/patient/:patientId/detail"
                    exact
                    component={() => <PatientDetail />}
                  />

                  {isAdminSanwa(userInfo) && (
                    <Route
                      path="/admin/csv/export"
                      exact
                      component={() => <CsvExportSanwa />}
                    ></Route>
                  )}

                  {isAdminFacility(userInfo) && (
                    <Route
                      path="/facility/csv/export"
                      exact
                      component={() => <CsvExportSanwa />}
                    ></Route>
                  )}

                  {isPartner(userInfo) && (
                    <Route
                      path="/partner/csv/export"
                      exact
                      component={() => <CsvExportSanwa />}
                    ></Route>
                  )}

                  <Route
                    path="/facility/:facilityId/payment-detail"
                    exact
                    render={(props) => <DetailPayment {...props} />}
                  />
                </Switch>

                {/* {location.pathname === "/" && (
                  
                )} */}
              </div>
            </Suspense>
          )}
        </Router>
        <ToastContainer />
      </I18nextProvider>
    </div>
  );
};

export default App;
